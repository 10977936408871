/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import apiSlice from '../../api/apiSlice'
// eslint-disable-next-line import/no-unresolved
import {
  ICorporationDetailParams,
  ICorporationDetailResponse,
  ICorporationListResponse,
  corporationSearchParamType
} from '../../../types/corporation'

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ['corporationsList']
})

export const corporationsSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    corporationList: builder.query<
      ICorporationListResponse,
      corporationSearchParamType
    >({
      query: ({
        searchText = '',
        currentPageNumber,
        rowLimitPerPage,
        sortColName = '',
        sortDirection = '',
        statusIdList
      }) => ({
        // TODO: need to be changed according to Api payload
        url: `private/corporation-contact-list?corporateListSearchParam=${searchText}&currentPageNumber=${currentPageNumber}&rowLimitPerPage=${rowLimitPerPage}&colName=${sortColName}&sortDirection=${sortDirection}&statusIdList=${statusIdList}`, // TODO: need to be changed according to Api payload
        method: 'GET',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
        // body: queryParam
      }),
      providesTags: ['corporationsList']
    }),
    corporationDetail: builder.query<
      ICorporationDetailResponse,
      ICorporationDetailParams
    >({
      query: ({ corp_info_id }) => ({
        url: `private/corporation-details?corporationInfoId=${corp_info_id}`,
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
    }),
    corporationListDevice: builder.query<
      ICorporationListResponse,
      corporationSearchParamType
    >({
      query: ({
        searchText = '',
        currentPageNumber,
        rowLimitPerPage,
        sortColName = '',
        sortDirection = ''
      }) => ({
        url: `private/corporationlistv2?corporateListSearchParam=${searchText}&currentPageNumber=${currentPageNumber}&rowLimitPerPage=${rowLimitPerPage}&colName=${sortColName}&sortDirection=${sortDirection}`,
        method: 'GET',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
        // body: queryParam
      })
    }),
    deleteCorporation: builder.mutation<any, any>({
      query: ({ corpId }) => ({
        url: `/private/corporation-delete?corpId=${corpId as string}`,
        method: 'DELETE',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['corporationsList']
    })
  })
})

export const {
  useCorporationListQuery,
  useLazyCorporationListQuery,
  useCorporationListDeviceQuery,
  useDeleteCorporationMutation,
  useLazyCorporationDetailQuery
} = corporationsSlice
