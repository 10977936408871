import { makeStyles } from '@mui/styles'

const inputStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    width: '100%',
    fontFamily: 'Noto Sans JP,sans-serif',
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #d9d9d9'
    },
    '& .Mui-disabled.MuiOutlinedInput-root:hover fieldset': {
      border: '1px solid #d9d9d9'
    }
  },
  container_label: {
    width: 'fit-content',
    fontSize: '14px',
    height: '20px'
  },
  container_asterisk: {
    color: '#E1341E',
    marginLeft: '3px',
    opacity: '80%'
  },
  container_textfield: {
    width: '100%',
    borderRadius: '6px',
    '& input, textarea': {
      padding: '8px 16px 10px',
      fontSize: '14px',
      color: '#232F3E',
      '&::placeholder': {
        color: '#6C7778',
        '-webkit-text-fill-color': '#6C7778',
        opacity: 1
      }
    },
    '& textarea': {
      paddingTop: '6px !important'
    },
    '& .MuiOutlinedInput-root': {
      height: '38px',
      '& fieldset': {
        borderColor: '#DDDDDD'
      },
      '&:hover fieldset': {
        borderColor: '#ABB7C5'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #9AA5B1',
        color: '#232F3E',
        opacity: 1
      },
      '& .MuiOutlinedInput-input.Mui-disabled': {
        color: '#939598',
        '-webkit-text-fill-color': '#939598'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0,
      borderRadius: '6px',
      '& .css-nnbavb': {
        display: 'none'
      }
    }
  },
  container_textfield_error: {
    width: '100%',
    '& input, textarea': {
      padding: '9px 12px',
      fontSize: '14px'
    },
    '& textarea': {
      paddingTop: '6px !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#DD0000 !important',
      top: 0,
      '& .css-nnbavb': {
        display: 'none'
      }
    }
  },
  container_textfield_disabled: {
    width: '100%',
    borderRadius: '4px',
    '& input, textarea': {
      padding: '9px 12px',
      fontSize: '14px',
      '&:disabled': {
        color: 'white'
      }
    },
    '& textarea': {
      paddingTop: '6px !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0,
      '& .css-nnbavb': {
        display: 'none'
      }
    }
  }
}))

export default inputStyles
