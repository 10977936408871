import React, { useState, useEffect } from 'react'
import {
  DialogTitle,
  DialogActions,
  IconButton,
  Box,
  Typography,
  Divider
} from '@mui/material'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import { useLazyGetEulaInformationQuery } from '../../../state/features/eula/eulaSlice'
import { ReactComponent as CloseIcon } from '../../../images/icon/close-ash.svg'
import { ReactComponent as UpdateIconWhite } from '../../../images/icon/update-white.svg'
import Button from '../../Atoms/Buttons/index'
import EulaCustomInput from '../../Atoms/EulaInputs'
import Loader from '../../Atoms/Loader/Loader'
import { useEulaDialogStyles, CustomDialog } from './style'

export interface dialogProps {
  open: boolean
  handleClose: () => void
  eulaType: string
  updateEulaInformation: (data: {
    version: number
    urlEn: string
    urlJp: string
    eulaType: string
  }) => void
  isLoadingEulaUpdate: boolean
}

const EulaUpdateDialog = ({
  open = true,
  handleClose,
  eulaType,
  updateEulaInformation,
  isLoadingEulaUpdate
}: dialogProps) => {
  const classes = useEulaDialogStyles()
  const [isFromSubmit, setIsFromSubmit] = useState<boolean>(false)

  const {
    handleSubmit,
    formState: { errors },
    register,
    trigger,
    setValue
  } = useForm({ mode: 'onSubmit' })

  const [
    eulaInformationTrigger,
    { data: eulaInformation, isFetching: eulaInformationIsFetching, isSuccess }
  ] = useLazyGetEulaInformationQuery()

  useEffect(() => {
    void eulaInformationTrigger({ eulaType })
  }, [])
  useEffect(() => {
    if (eulaInformationIsFetching || !isSuccess) {
      setValue('version', '')
      setValue('urlEn', '')
      setValue('urlJp', '')
    } else if (eulaInformation?.data) {
      setValue('version', eulaInformation?.data.version)
      setValue('urlEn', eulaInformation?.data.urlEn)
      setValue('urlJp', eulaInformation?.data.urlJp)
    }
  }, [eulaInformation, isSuccess, eulaInformationIsFetching])

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
 
    const updatedData = {
      version: data.version,
      urlEn: data.urlEn,
      urlJp: data.urlJp,
      eulaType: eulaType
    }
    updateEulaInformation(updatedData)
  }

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      {(eulaInformationIsFetching || isLoadingEulaUpdate) && (
        <Box className={classes.loaderParent}>
          <Loader colorCode='#939598' />
        </Box>
      )}

      <DialogTitle>
        <Box style={{ position: 'relative' }}>
          <Typography className={classes.dialogTitle}>
            {eulaType === 'ToU'
              ? 'Update Terms of Use'
              : 'Update Privacy Policy'}
          </Typography>
        </Box>
        <IconButton
          aria-label='close'
          onClick={() => handleClose()}
          className={classes.iconBtn}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogActions className={classes.actions}>
        <form
          className={classes.actions_form}
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit(onSubmit)(e)
            setIsFromSubmit(true)
          }}
        >
          <div className={classes.actions_row}>
            <EulaCustomInput
              register={register}
              registerField='version'
              label='Version'
              placeholder='Type Version'
              errors={errors}
              trigger={trigger}
              setValue={setValue}
              min={1}
              max={45}
              required
              isFromSubmit={isFromSubmit}
              isNumeric
            />
          </div>
          <div className={classes.actions_row_url}>
            <EulaCustomInput
              register={register}
              registerField='urlEn'
              label='English Ver. URL'
              placeholder='Type English Ver. URL'
              pattern={{
                pattern:
                  /^https?:\/\/(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/,
                errorMessage: 'This is not a valid URL'
              }}
              errors={errors}
              trigger={trigger}
              setValue={setValue}
              min={1}
              max={256}
              isFromSubmit={isFromSubmit}
              required
            />
          </div>
          <div className={classes.actions_row_url}>
            <EulaCustomInput
              register={register}
              registerField='urlJp'
              label='Japanese Ver. URL'
              placeholder='Type Japanese Ver. URL'
              pattern={{
                pattern:
                  /^https?:\/\/(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/,
                errorMessage: 'This is not a valid URL'
              }}
              errors={errors}
              trigger={trigger}
              setValue={setValue}
              min={1}
              max={256}
              isFromSubmit={isFromSubmit}
              required
            />
          </div>
          <Divider className={classes.actions_row_divider} />
          <div className={classes.actions_row_buttons}>
            <Button
              onClick={() => handleClose()}
              color='secondaryTwo'
              sx={{ width: '91px' }}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              color='primary'
              sx={{ gap: '6px', width: '117px' }}
            >
              <UpdateIconWhite />
              Update
            </Button>
          </div>
        </form>
      </DialogActions>
    </CustomDialog>
  )
}

export default EulaUpdateDialog
