import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'
import { Dialog } from '@mui/material'

const useEulaDialogStyles = makeStyles(() => ({
  iconBtn: {
    position: 'absolute',
    padding: '8px',
    right: 24,
    top: 24,
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '& svg': {
      fill: '#abb7c5'
    }
  },
  dialogTitle: {
    width: '80%',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '26px',
    color: '#232f3e'
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  actions_form: {
    width: '100%'
  },
  actions_row: {
    width: '100%',
    display: 'flex',
    marginTop: '15px',
    justifyContent: 'space-between'
  },
  actions_row_url: {
    width: '100%',
    display: 'flex',
    marginTop: '12px',
    justifyContent: 'space-between'
  },
  actions_row_divider: {
    width: '100%',
    background: '#FFFFFF',
    borderColor: '#DDDDDD',
    height: '1px',
    opacity: 1,
    marginTop: '20px'
  },
  actions_row_buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: '12px',
    gap: '10px'
  },
  loaderParent: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(255,255,255, 0.7)',
    width: '100%',
    height: '100%',
    zIndex: 1
  }
}))

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    marginTop: '100px'
  },
  '& .MuiPaper-root': {
    width: '580px',
    borderRadius: '10px',
    maxWidth: 'unset !important',
    padding: '24px 32px 30px',
    marginTop: '0px'
  },
  '& .MuiDialogTitle-root': {
    padding: 0
  },
  '& .MuiDialogActions-root': {
    padding: '0',
    justifyContent: 'left'
  },
  '& .MuiDialog-container': {
    height: 'unset !important',
    marginTop: '100px',
    '& :not(:first-of-type)': {
      marginLeft: '0'
    }
  }
}))

export { useEulaDialogStyles, CustomDialog }
