/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-props-no-spreading */
import { TextField } from '@mui/material'
import React, { useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FieldValues,
  UseFormRegister,
  UseFormTrigger,
  UseFormSetValue
} from 'react-hook-form'
import FormErrorMessage from '../FormErrorToolTip'
import inputStyles from './style'

export interface CustomInputProps {
  label?: string
  placeholder?: string
  register: UseFormRegister<FieldValues>
  registerField: string
  required: boolean
  pattern?: {
    pattern: RegExp
    errorMessage: string
  }
  errors: {
    [x: string]: {
      message: string
    }
  }
  errorMessage?: string
  disabled?: boolean
  min?: number
  max?: number
  isNumeric?: boolean
  isFromSubmit?: boolean
  trigger: UseFormTrigger<FieldValues>
  setValue: UseFormSetValue<FieldValues>
}

const EulaCustomInput: React.FC<CustomInputProps> = ({
  label = '',
  placeholder = '',
  errors,
  register,
  registerField,
  required,
  pattern,
  disabled,
  min,
  max,
  isNumeric,
  isFromSubmit,
  trigger,
  setValue
}) => {
  const styles = inputStyles()
  const { t } = useTranslation()

  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    // Only allow numeric input, including decimals
    const numericValue = event.target.value.replace(/[^0-9.]/g, '')
    setValue(registerField, isNumeric ? numericValue : event.target.value)
    if (isFromSubmit) {
      await trigger(registerField)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.container_label}>
        {label}
        {required ? <span className={styles.container_asterisk}>*</span> : null}
      </div>
      <FormErrorMessage
        isError={!!errors[registerField]?.message}
        message={errors[registerField]?.message}
      >
        <TextField
          {...register(registerField, {
            required: {
              value: required,
              message: `${t('commonErrorMessage.required')}`
            },
            pattern: {
              value: pattern?.pattern ?? /(.*?)/,
              message: pattern?.errorMessage ?? ''
            },
            minLength: {
              value: min ? min : 0,
              message: `${t('commonMessage.minInput')} ${min ? min : 0} ${t(
                'commonMessage.characters'
              )}`
            },
            maxLength: {
              value: max ? max : Number.MAX_VALUE,
              message: `${t('commonMessage.maxInput')} ${
                max ? max : Number.MAX_VALUE
              } ${t('commonMessage.characters')}`
            }
          })}
          sx={
            disabled
              ? {
                  backgroundColor: '#FAFAFA !important'
                }
              : {}
          }
          disabled={disabled}
          onChange={handleInputChange}
          placeholder={placeholder}
          className={
            errors[registerField]
              ? styles.container_textfield_error
              : styles.container_textfield
          }
        />
      </FormErrorMessage>
    </div>
  )
}

export default EulaCustomInput
