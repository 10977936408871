import { createTheme, Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { grey } from '@mui/material/colors'
import { ptColors } from './settings/colors'

const headerHeight = '62px' // ヘッダー高さ
const drawerWidth = 250 // ドロワーメニュー幅
const mainBackgroundColor = '#EAEDED' // メイン背景色
const primaryColor = '#007EB4' // メインカラー
const secondaryColor = '#313133' // セカンダリカラー
const primaryTextColor = '#232F3E' // テキスト色
const primaryBackgroundColor = '#FAFAFA' // メイン背景色
const primaryBorderColor = '#D9D9D9' // ボーダー色
const primaryBoxShadowColor = '#00000029' // ボーダー色
const secondaryTextColor = '#6C7778' // テキスト色
const secondaryBorderColor = '#ABB7C5' // ボーダー色
const secondaryBackgroundColor = '#dd0000' // メイン背景色
const tertiaryBorderColor = '#0078AB' // ボーダー色
const octanaryBorderColor = '#ddd' // ボーダー色
export const whiteColor = '#FFFFFF' // 白色

export const theme: Theme = createTheme({
  typography: {
    fontFamily: ['Noto Sans JP', 'sans-serif'].join(',')
  },
  palette: {
    primary: { main: primaryColor },
    secondary: {
      main: grey[200]
    },
    background: {
      default: mainBackgroundColor
    },
    text: {
      primary: primaryTextColor
    },
    success: {
      main: '#4DC176'
    },
    error: {
      main: '#E1341E'
    }
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: '#37475A'
        },
        tooltip: {
          backgroundColor: '#37475A'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    }
  }
})

export const useStyles = makeStyles(() => ({
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  pageTitle: {
    marginBottom: theme.spacing(1)
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  // appBarSpacer: theme.mixins.toolbar,
  // content: {
  //   flexGrow: 1,
  //   height: '100vh',
  //   overflow: 'auto',
  //   backgroundColor: '#EAEDED'
  // },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    '& .MuiContainer-root': {
      maxWidth: 700
    }
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  },
  field: {
    '& .MuiFormLabel-root.Mui-disabled': {
      color: theme.palette.text.primary
    }
  },
  errorInput: {
    border: 'solid 2px red'
  },
  corpIdListBox: {
    textAlign: 'left'
  },
  corpInfoTable: {
    width: 552,
    '&:last-child td': {
      width: '50px',
      minWidth: '50px'
    },
    '&:last-child th, &:last-child td': {
      borderBottom: 0,
      paddingTop: 5,
      paddingBottom: 5,
      paddingRight: 10,
      paddingLeft: 10
    }
  },
  corpInfoTableGrid: {
    width: 552
  },
  companyList: {
    '& .MuiTableCell-root': {
      minWidth: '150px',
      maxWidth: '400px'
    }
  },
  dialogButton: {
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
      paddingBottom: '1.5rem'
    },
    '& .MuiButton-root': {
      width: '105px'
    }
  },
  // old style end
  // new style start
  root: {
    backgroundColor: mainBackgroundColor,
    display: 'flex'
  },
  mainContent: {
    flexGrow: 1,
    textAlign: 'unset',
    backgroundColor: 'transparent',
    marginTop: '62px',
    height: 'calc(100vh - 62px)'
    // padding: '30px 40px 40px'
  },
  // header topbar style start
  appBar: {
    '& .MuiPaper-root': {
      height: headerHeight,
      background: whiteColor,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    }
  },
  appbarToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#37475A',
    minHeight: '62px',
    paddingLeft: '71px',
    paddingRight: '40px'
  },
  logo: {
    width: '108px',
    height: '25px',
    alignSelf: 'start',
    marginTop: '16px'
  },
  // header right side avatar and text style start
  accountName: {
    fontSize: '14px',
    color: whiteColor
  },
  avatar: {
    width: '32px',
    height: '32px',
    backgroundColor: '#E2F4F7',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '20px',
    marginLeft: '10px',
    marginRight: '10px',
    border: `2px solid ${whiteColor}`,
    color: secondaryBorderColor
  },
  avatarArrow: {
    width: '9px',
    height: '5px'
  },
  // header right side avatar and text style end
  // header right side avatar dropdown style start
  accountMenu: {
    backgroundColor: whiteColor,
    boxShadow: '0px 3px 20px rgba(0, 0, 0, 16%)',
    width: '150px',
    borderRadius: '6px',
    zIndex: 1300,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '1px',
      right: '15px',
      width: '10px',
      height: '10px',
      backgroundColor: whiteColor,
      borderRadius: '1px',
      transform: 'translateY(-50%) rotate(45deg)'
    },
    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiListItemButton-root': {
        padding: '10px 12px',
        color: secondaryTextColor,
        fontSize: '12px',
        lineHeight: '16px',
        '&:hover ': {
          backgroundColor: primaryBackgroundColor,
          color: primaryTextColor
        }
      },
      '& .MuiListItemButton-root:last-child': {
        paddingBottom: '9px'
      }
    },
    '& .MuiListItemButton-root:first-child': {
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px'
    },
    '& .MuiListItemButton-root:not(:last-child)': {
      borderBottom: `1px solid ${primaryBorderColor}`
    },
    '& .MuiListItemButton-root:last-child': {
      borderBottomLeftRadius: '6px',
      borderBottomRightRadius: '6px'
    }
  },
  // header right side avatar dropdown style end
  // header topbar style end
  // left sidebar menu start
  drawerBox: {
    padding: '62px 0px 0 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: whiteColor,
    height: '100%',
    color: primaryTextColor
  },
  listItemSidebar: {
    padding: '30px 15px',
    textDecoration: 'none'
  },
  navLink: {
    display: 'block',
    textDecoration: 'none',
    fontFamily: "'Noto Sans JP', sans-serif",
    '& .MuiListItemButton-root': {
      width: '220px',
      height: '50px',
      backgroundColor: primaryBackgroundColor,
      border: `1px solid ${secondaryBorderColor}`,
      borderRadius: '6px',
      color: primaryTextColor,
      fontSize: '14px',
      marginBottom: '8px',
      textTransform: 'capitalize',
      gap: '8px',
      transition: 'all ease .3s',
      '&:hover': {
        backgroundColor: '#F8FDFF',
        border: '1px solid #9ABFCF',
        color: primaryColor
      },
      '&:focus': {
        outline: '5px solid #CCEBF9'
      }
    },
    '&.active': {
      '& .MuiListItemButton-root': {
        backgroundColor: primaryColor,
        border: `1px solid ${tertiaryBorderColor}`,
        color: whiteColor,
        '&:hover': {
          backgroundColor: primaryColor,
          border: `1px solid ${tertiaryBorderColor}`,
          color: whiteColor
        },
      }
    }
  },
  // menu list item icon
  listItemIcon: {
    // height: '15px',
    width: '18px',
    minWidth: '18px',
    justifyContent: 'left',
    marginRight: 'auto',
    marginLeft: 'auto',
    color: primaryTextColor
  },
  // left sidebar menu end
  // breadcrumb design start
  breadcrumb: {
    fontSize: '13px !important',
    '& .MuiBreadcrumbs-separator': {
      margin: '0 7px',
      color: '#939598'
    }
  },
  breadcrumbLink: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    margin: 0,
    padding: 0,
    fontSize: '14px !important',
    height: '19px',
    color: primaryColor,
    transition: '0.15s color',
    '&:hover': {
      textDecoration: 'none',
      color: '#006D9C'
    }
  },
  breadcrumbActiveLink: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    fontSize: '14px',
    height: '19px',
    margin: 0,
    padding: 0,
    color: secondaryTextColor,
    cursor: 'text',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  breadcrumbTitle: {
    textAlign: 'left',
    marginTop: '4px',
    display: 'flex',
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '25px',
    lineHeight: '29px',
    fontFamily: "'Noto Sans JP', sans-serif",
    color: primaryTextColor
  },
  // breadcrumb design end
  // table select number of rows start
  paginationButtonWrapper: {
    height: 54,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 20px',
    '& p': {
      fontSize: 13
    }
  },
  paginationRowBtn: {
    border: `1px solid ${octanaryBorderColor}`,
    width: '54px',
    height: '26px',
    backgroundColor: whiteColor,
    borderRadius: '4px',
    fontSize: '12px',
    color: primaryTextColor,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
    // '& svg': {
    //   width: '9px',
    //   height: '4px',
    // },
  },
  paginationRowMenu: {
    backgroundColor: whiteColor,
    boxShadow: `0px 3px 6px ${primaryBoxShadowColor}`,
    width: '92.5px',
    borderRadius: '6px',
    zIndex: 1300,
    // '&:before': {
    //   content: '""',
    //   position: 'absolute',
    //   right: '12px',
    //   width: '12px',
    //   height: '12px',
    //   backgroundColor: whiteColor,
    //   borderRadius: '1px',
    //   transform: 'translateY(-50%) rotate(45deg)',
    //   zIndex: -1
    // },
    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
      '& .MuiListItem-root': {
        padding: '6px 12px',
        fontSize: '12px',
        justifyContent: 'end',
        color: secondaryTextColor
      },
      '& .MuiListItem-root:first-child': {
        borderTopRightRadius: '6px',
        borderTopLeftRadius: '6px'
      },
      '& .MuiListItem-root:not(:last-child)': {
        borderBottom: '0.5px solid #EAEAEB'
      }
    },
    '&[data-popper-placement*="top"]': {
      boxShadow: `0px -3px 6px ${primaryBoxShadowColor}`,
      '&:before': {
        bottom: '-12px'
      }
    },
    '&[data-popper-placement*="bottom"]:before': {
      top: '0'
    }
  },
  // table select number of rows end
  // common search input style start
  searchInputFilter: {
    backgroundColor: whiteColor,
    width: '395px',
    height: '38px',
    padding: '0 16px',
    borderRadius: '4px',
    boxShadow: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: octanaryBorderColor
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: secondaryBorderColor
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
      lineHeight: '14px',
      padding: '0',
      color: primaryTextColor,
      '&::placeholder': {
        color: secondaryTextColor,
        opacity: 1
      }
    },
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
    '&.MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        border: '1px solid #9AA5B1'
      }
    }
  },
  fileInput: {
    position: 'relative',
    padding: '4px 4px 4px 16px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '38px',
    '&:hover': {
      border: '1px solid #9AA5B1'
    },
  },
  fileInput_borderNormal: {
    border: '1px solid #DDDDDD',
    '&:focus': {
      border: '1px solid #9AA5B1',
      outline: 'none'
    }
  },
  fileInput_borderError: {
    border: `1px solid ${secondaryBackgroundColor}`
  },
  fileInput_input: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
    '&:placeholder': {
      color: '#6C7778'
    }
  },
  fileInput_placeholder: {
    fontSize: '14px',
    lineHeight: '14px',
    color: secondaryTextColor
  },
  fileInput_text: {
    height: '30px',
    width: '100px',
    padding: '7px 15px',
    backgroundColor: primaryColor,
    border: tertiaryBorderColor,
    color: whiteColor,
    borderRadius: '1px 4px 4px 1px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    lineHeight: '16px'
  },
  fileInput_fileName: {
    color: primaryTextColor,
    fontSize: '14px',
    lineHeight: '19px'
  },

  formError: {
    position: 'relative'
  },
  formError_message: {
    position: 'absolute',
    top: '-46px',
    right: 0,
    padding: '10px',
    backgroundColor: secondaryBackgroundColor,
    color: '#fff',
    borderRadius: '6px',
    boxShadow: `0px 3px 6px ${primaryBoxShadowColor}`,
    fontSize: '12px',
    lineHeight: '16px',
    '&::after': {
      content: "''",
      position: 'absolute',
      right: '19px',
      bottom: ' -5px',
      borderColor: `${secondaryBackgroundColor} transparent transparent transparent`,
      borderRadius: '1px',
      width: '12px',
      height: ' 12px',
      backgroundColor: secondaryBackgroundColor,
      transform: 'rotate(45deg)'
    }
  },
  // write fileInput_error:after css

  login: {
    paddingTop: '102px',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: mainBackgroundColor
  },
  login__container: {
    width: '560px',
    backgroundColor: whiteColor,
    padding: '32px',
    borderRadius: '12px'
  },
  login__logo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  login__text: {
    fontSize: '20px',
    lineHeight: '30px',
    color: primaryTextColor,
    fontWeight: '700',
    marginTop: '9.24px'
  },
  login__btn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '16px',
    padding: '10px',
    height: '44px',
    width: '100%',
    borderRadius: '4px',
    border: `1px solid ${primaryBorderColor}`,
    backgroundColor: primaryBackgroundColor,
    textTransform: 'none',
    marginTop: '30px'
  },
  login__btntext: {
    fontSize: '16px',
    lineHeight: '16px',
    color: primaryTextColor,
    fontWeight: '700'
  },
  // common search input style end
  // new style end
  // group table start
  tableCard: {
    minHeight: 'calc(100vh - 258px)',
    backgroundColor: whiteColor,
    border: '1px solid #EAEDED',
    borderRadius: '8px',
    padding: '22px 30px 30px',
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'column',
    '@media screen and (max-width: 1440px)': {
      minHeight: 'calc(100vh - 247px)'
    },
    '@media screen and (max-width:1336px)': {
      minHeight: 'calc(100vh - 237px)'
    }
  },
  // verify page start
  verify: {
    paddingTop: '102px',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: mainBackgroundColor
  },
  verify__card: {
    width: '560px',
    backgroundColor: whiteColor,
    padding: '32px',
    borderRadius: '12px',
    textAlign: 'center'
  },
  verify__text: {
    fontSize: '18px',
    lineHeight: '30px',
    marginTop: '12px',
    color: primaryTextColor
  },
  verify__textSuccess: {
    color: '#8AB165'
  },
  verify__textError: {
    color: '#DD0000'
  },
  verify__btn: {
    fontSize: '16px',
    lineHeight: '16px',
    backgroundColor: primaryColor,
    color: whiteColor,
    marginTop: '22px',
    width: '100%',
    height: '44px',
    textTransform: 'unset',
    fontWeight: '500',
    borderRadius: '4px',
    display: 'block',
    textDecoration: 'none',
    padding: '12px',
    fontFamily: `"Noto Sans JP", sans-serif`,
    '&:hover': {
      backgroundColor: '#006D9C',
      color: whiteColor
    }
  },
  verify__loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh'
  },
  verify__loadingcontent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: '12px'
  },
  // verify page end
  // custom toastify start
  customToastify: {
    width: '744px',
    height: '52px',
    top: '1.5px',
    '& .Toastify__toast': {
      boxShadow: '0px 3px 6px #00000029',
      width: '744px',
      minHeight: '52px',
      padding: '0 16px',
      borderRadius: '6px',
      alignItems: 'center',
      fontSize: '14px',
      // marginBottom: 0,
      marginBottom: '5px',
      '& .Toastify__toast-body': {
        margin: 0,
        padding: 0
      }
    },
    '& .Toastify__toast-icon': {
      width: '18px'
    },
    '& .Toastify__toast--error': {
      backgroundColor: secondaryBackgroundColor
    },
    '& .Toastify__toast--success': {
      backgroundColor: '#80AA57'
    }
  },
  dataTable: {
    whiteSpace: 'nowrap',
    marginBottom: 0,
    border: '1px solid #dddddd',
    borderCollapse: 'separate',
    borderSpacing: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottom: 'none',
    wordWrap: 'break-word',
    height: ' auto !important',
    '& > :not(:first-child)': {
      borderTop: 'none'
    },
    '& >:not(caption)>*>*': {
      borderBottomWidth: '0px !important',
      padding: 0
    }
  },
  dataTableBody: {
    //
  },
  dataTableMulti:{
    // display: "none"
  },
  tableDataRowSkeleton: {
    height: '47px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  tableDataRowSkeletonEula: {
    height: '47px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:last-child': {
      borderBottom: '1px solid #DDDDDD'
    }  
  },
  tableHeaderJustifyBetween: {
    justifyContent: 'space-between'
  },
  col: {
    '&:first-child': {
      paddingLeft: '20px'
    },
    '&:last-child': {
      paddingRight: '20px'
    },
    '& .MuiSkeleton-root': {
      borderRadius: '3px !important'
    }
  },
  tableDataRow: {
    verticalAlign: 'middle',
    fontSize: 14,
    height: "47px",
    display: "flex",
    borderBottom: '1px solid #dddddd',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: `${ptColors.alabaster}`,
      boxShadow: ' 0px 0px 10px rgba(0, 0, 0, 0.1)',
      position: 'relative',
      zIndex: 99
    },
    '& > div:first-child': {
      paddingLeft: '20px'
    },
  },
  tableDataRow_multiline: {
    height: "68px",
  },
  noHover: {
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none'
    }
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  tableItemData: {
    display: 'inline-block',
    fontFamily: "'Noto Sans JP', sans-serif",
    height: '25px',
    lineHeight: '25px',
    color: `${ptColors.ebonyClay}`
  },
  tableRowContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  linkStyleBtn: {
    border: 'none',
    background: 'none',
    color: `${ptColors.cerulean}`,
    paddingLeft: 0,
    whiteSpace: 'pre',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tableBodySimpleBar: {
    height: 'calc(100vh - 463px)',
    padding: 0
  },
  // custom toastify end
  dataTableWrap: {
    marginTop: 20,
    color: `${ptColors.ebonyClay}`,
    overflowY: 'hidden',
    position: 'relative'
  },
  dataPagination: {
    position: 'relative',
    zIndex: 1,
    height: 54,
    border: `1px solid ${ptColors.gainsboro}`,
    borderRadius: '0 0 4px 4px',
    marginTop: -1,
    backgroundColor: `${ptColors.alabaster}`
  },
  tableDataCount: {
    display: 'flex',
    fontSize: 18,
    height: '24px',
    fontWeight: 'bold',
    color: `${ptColors.ebonyClay}`,
    letterSpacing: '0.11px'
  },
  dataNotFoundBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'calc(100vh - 336px)',
    '& p': {
      marginBottom: 0
    }
  },
  noFountText: {
    fontSize: 20,
    fontWeight: 500,
    height: '27px',
    lineHeight: '23px',
    color: `${ptColors.dune}`,
    marginTop: '13px'
  },
  noAvailableText: {
    fontSize: 14,
    lineHeight: '23px',
    height: '19px',
    marginTop: '3px',
    color: `${ptColors.mountainMist}`
  },
  importBtn: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    padding: '9px 15px',
    height: '38px !important',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '14px'
  },
  mt_0: {
    marginTop: 0
  },
  searchBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  searchBtn: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    padding: '9px 15px',
    height: '38px !important',
    fontSize: '14px !important',
    fontWeight: 400
  },
  rotateIcon: {
    transform: 'rotate(19deg)'
  },
  // abc:{
  // fontSize: "14px"
  // position: "relative",
  // overflow: "hidden",
  // maxHeight: "2.4em",
  // lineHeight: "2em",
  // display: "inline-block",
  // wordWrap: "break-word",
  // height: "45px",
  // width: "250px",
  // whiteSpace: "normal",
  // maxHeight: "70px"
  // },
  // ellipsis:{
  //   "&::after":{
  //     content: "...",
  //     position: "absolute",
  //     right: "-12px",
  //     bottom: "4px"
  //   }
  // },
  deleteIconBox:{
    position: 'relative',
    marginRight: '20px',
    borderRadius: '19px',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    }
  },
  deleteIconBtn: {
    padding: '0px',
    background: 'none',
    borderRadius: '19px',
    boxShadow: 'none',
    minWidth: 'unset',
    width: '38px',
    height: '38px',
    '&:hover': {
      background: 'none',
      boxShadow: 'none'
    },
  },
  deletePopper: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #00000029',
    border: '0.5px solid rgba(217, 217, 217, .5)',
    borderRadius: '4px',
    zIndex: 1300,
    color: '#313133',
    fontSize: '14px',
    lineHeight: '16px',
    position: 'absolute',
    top: '25px',
    right: '22px',
    opacity: 1
  },
  deletePopperBtn: {
    display: 'flex',
    gap: '5px',
    padding: '7px 14px 8px 14px',
    '& svg': {
      width: '20px',
      height: '20px'
    },
    '&:hover': {
      color: '#E1341E',
      backgroundColor: '#EAEAEB',
      '& svg': {
        '& path': {
          stroke: '#E1341E'
        }
      }
    }
  },
  eulaListCard: {
    height: '237px',
    padding: '20px 30px 30px',
    border: '1px solid #EAEDED',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    marginTop: '75px',
    '@media screen and (max-width: 700px)': {
      height: 'unset'
    }
  },
  eulaTableTitle: {
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: 'bold',
    color: `${ptColors.ebonyClay}`,
    letterSpacing: '0.11px',
    marginBottom: '22px'
  },
  eulaUpdateBtn: {
    padding: '0px',
    color: '#007EB4',
    textTransform: 'none',
    boxShadow: 'none',
    fontSize: '12px',
    minWidth: '60px',
    gap: '4.84px',
    fontWeight: 400,
    marginRight: '20px',
    '&:hover': {
      color: '#006D9C',
      background: '#FFFFFF',
      '& svg': {
        '& path': {
          stroke: '#006D9C'
        }
      }
    }
  },
  searchBlockWithFilter: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
  },
  statusSelectTitle: {
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '6px',
    color: '#232f3e'
  },
  statusSelectPlaceholder: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#6C7778'
  },
  selectedStatusCard: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  statusSelectBox: {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    width: '100%',
    height: '38px',
    fontSize: '14px',
    fontWeight: '400',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 12px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    justifyContent: 'space-between',
    opacity: '1',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid #939598'
    }
  },
  selectedStatusBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#232F3E',
    background: '#EAEDED',
    padding: '2px 8px 3px',
    fontSize: '12px',
    fontWeight: '400',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    lineHeight: '17px',
    cursor: 'default'
  },
  selectedStatusCrossIcon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '3px'
  },
  selectStatusModel: {
    borderRadius: '6px',
    boxShadow: '0px 3px 20px #00000029',
    top: '45px',
    zIndex: 1300
  },
  selectStatusPopperBox: {
    maxHeight: '226px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 20px #00000029',
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
    opacity: '1'
  },
  allBoxCard: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    height: '36px',
    paddingLeft: '12px'
  },
  allButton: {
    textTransform: 'none',
    fontSize: '12px',
    lineHeight: '17px',
    padding: '2px 12px 3px 13px',
    borderRadius: '4px',
    height: '22px',
    fontWeight: '700',
    color: '#FFFFFF',
    backgroundColor: '#007EB4',
    '&:hover': {
      borderColor: '#0078AB',
      backgroundColor: '#006D9C'
    },
    '&.Mui-disabled': {
      color: '#FFFFFF',
      backgroundColor: '#007EB4',
      opacity: 0.5
    }
  },
  statusItem: {
    padding: '6px 12px',
    borderTop: '1px solid #D9D9D9',
    borderRight: 'none',
    borderLeft: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '393px',
    height: '36px',
    fontSize: '12px',
    fontWeight: '400',
    '&:hover': {
      backgroundColor: '#FAFAFA'
    },
    '&:last-child': {
      borderBottomLeftRadius: '6px',
      borderBottomRightRadius: '6px'
    }
  },
  statusItemText: {
    fontSize: '12px',
    fontWeight: '400',
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    '&::after': {
      content: "''",
      display: 'block'
    }
  },
  statusItemSkeletonBox: {
    border: '1px solid #D9D9D9',
    borderRight: 'none',
    borderLeft: 'none',
    width: '393px',
    height: '36px',
    backgroundColor: '#ffffff',
    paddingLeft: '16px'
  },
  statusItemSkeleton: {
    width: '380px',
    height: '20px'
  }
}))
