import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DoneIcon from '@mui/icons-material/Done'
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Popper,
  ClickAwayListener,
  Paper,
  Skeleton,
  Button
} from '@mui/material'
import { useStyles } from '../../../themes/index'
import { ReactComponent as CloseIcon } from '../../../images/icon/close-small.svg'
// import { statusFilterType, useStatusListQuery } from '../../../state/features/corporations/statusSlice'
import { statusFilterType } from '../../../state/features/corporations/statusSlice'

export interface StatusFilterProps {
  setSelectedStatusList: Dispatch<SetStateAction<statusFilterType[]>>
}

const StatusFilter: React.FC<StatusFilterProps> = ({
  setSelectedStatusList
}) => {
  const classes = useStyles()

  // const { data: statusList, isFetching: statusListIsReloading } =
  // useStatusListQuery()
  
  const statusList = [
    {
      id: 1,
      status_name: 'Not In Use'
    },
    {
      id: 2,
      status_name: 'In Trial'
    },
    {
      id: 3,
      status_name: 'Trial Expired'
    },
    {
      id: 4,
      status_name: 'In Use'
    },
    {
      id: 5,
      status_name: 'Locked'
    }
  ]
  const statusListIsReloading = false

  const [selectAnchor, setSelectAnchor] = useState<null | HTMLElement>(null)
  const [selectOpen, setSelectOpen] = useState<boolean>(false)
  const [selectedStatus, setSelectedStatus] = useState<statusFilterType[]>([])

  // Select tag status toggling
  const handleSelectStatusOpen = (event: MouseEvent | TouchEvent) => {
    event.preventDefault()
    if (selectOpen) {
      setSelectOpen(false)
    } else {
      setSelectOpen(true)
    }
  }
  // select box click status
  const handleSelectBoxClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setSelectAnchor(event.currentTarget)
    if (selectOpen) {
      setSelectOpen(false)
    } else {
      setSelectOpen(true)
    }
  }

  const handleSelectStatus = (info: statusFilterType) => {
    const index = selectedStatus.findIndex((status) => status.id === info.id)
    if (index !== -1) {
      const updatedStatus = [...selectedStatus]
      updatedStatus.splice(index, 1)
      setSelectedStatus(updatedStatus)
    } else {
      setSelectedStatus([...selectedStatus, info])
    }
  }
  const handleItemRemove = (info: statusFilterType) => {
    const index = selectedStatus.findIndex((status) => status.id === info.id)

    if (index !== -1) {
      const updatedStatus = [...selectedStatus]
      updatedStatus.splice(index, 1)
      setSelectedStatus(updatedStatus)

      if (!selectOpen) {
        setSelectedStatusList(updatedStatus)
      }
    }
  }
  const handleAllSelect = () => {
    if (statusList) {
      setSelectedStatus(statusList)
    }
  }
  const handleAllClear = () => {
    setSelectedStatus([])
  }

  useEffect(() => {
    if (!selectOpen) {
      setSelectedStatusList(selectedStatus)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOpen])

  return (
    <Box sx={{ marginTop: '-25px' }}>
      <Typography className={classes.statusSelectTitle}>
        Filter by Status
      </Typography>

      <FormControl sx={{ width: '395px', height: '38px' }}>
        <Box
          onClick={handleSelectBoxClick}
          sx={{
            border: selectOpen ? '1px solid #9AA5B1' : '1px solid #DDDDDD'
          }}
          className={classes.statusSelectBox}
        >
          <Box className={classes.selectedStatusCard}>
            {selectedStatus.length > 0 ? (
              selectedStatus.map((item: statusFilterType) => (
                <Box
                  key={item?.status_name}
                  className={classes.selectedStatusBox}
                  onClick={(event) => {
                    event.stopPropagation()
                  }}
                >
                  {item?.status_name}
                  <Box
                    className={classes.selectedStatusCrossIcon}
                    onClick={(event) => {
                      event.stopPropagation()
                      handleItemRemove(item)
                    }}
                  >
                    <CloseIcon />
                  </Box>
                </Box>
              ))
            ) : (
              <Typography className={classes.statusSelectPlaceholder}>
                Select Status
              </Typography>
            )}
          </Box>
          <KeyboardArrowDownIcon sx={{ width: '20px', height: '20px' }} />
        </Box>
        <Popper
          placement='bottom-start'
          anchorEl={selectAnchor}
          open={selectOpen}
          className={classes.selectStatusModel}
        >
          <ClickAwayListener onClickAway={handleSelectStatusOpen}>
            <Paper className={classes.selectStatusPopperBox}>
              <Box className={classes.allBoxCard}>
                <Button
                  className={classes.allButton}
                  onClick={handleAllSelect}
                  disabled={
                    statusList && statusList?.length <= selectedStatus.length
                  }
                >
                  All Select
                </Button>
                <Button
                  onClick={handleAllClear}
                  className={classes.allButton}
                  disabled={!(selectedStatus.length >= 1)}
                >
                  All Clear
                </Button>
              </Box>
              <Box>
                {statusListIsReloading ? (
                  <>
                    <MenuItem className={classes.statusItemSkeletonBox}>
                      <Skeleton className={classes.statusItemSkeleton} />
                    </MenuItem>
                    <MenuItem
                      className={classes.statusItemSkeletonBox}
                      sx={{
                        borderBottomLeftRadius: '6px',
                        borderBottomRightRadius: '6px'
                      }}
                    >
                      <Skeleton className={classes.statusItemSkeleton} />
                    </MenuItem>
                  </>
                ) : (
                  statusList &&
                  statusList?.length > 0 &&
                  statusList?.map((item: statusFilterType) => {
                    const isItemSelected = selectedStatus.some(
                      (status: statusFilterType) => status?.id === item.id
                    )

                    return (
                      <MenuItem
                        key={item?.id}
                        className={classes.statusItem}
                        sx={{
                          backgroundColor: isItemSelected
                            ? '#FAFAFA'
                            : '#ffffff',
                          width: '393px'
                        }}
                        value={item?.id}
                        onClick={() => {
                          handleSelectStatus(item)
                        }}
                      >
                        <Typography
                          className={classes.statusItemText}
                          sx={{
                            width: isItemSelected ? '208px' : '228px',
                            color: isItemSelected ? '#232F3E' : '#6C7778'
                          }}
                        >
                          {item?.status_name}
                        </Typography>

                        {isItemSelected && (
                          <DoneIcon
                            sx={{
                              height: '20px',
                              width: '20px',
                              color: '#009DE1'
                            }}
                          />
                        )}
                      </MenuItem>
                    )
                  })
                )}
              </Box>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </FormControl>
    </Box>
  )
}

export default StatusFilter
