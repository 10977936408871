import React, { useEffect, useState, useRef } from 'react'
import { Box, Typography, Button, ListItemButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import SimpleBar from 'simplebar-react'
import PageTemplate from '../PageTemplate'
import { useStyles } from '../../../themes/index'
import {
  TABLE_ROW_NUMBER_PER_PAGE,
  PAGE_PATH_NAME,
  STATUS_BG_COLOR,
  STATUS_TEXT
} from '../../../constants/constants'
import { DIRECTION_TYPE, SortConfig } from '../../../types/datatable'
import CustomTooltip from '../../Atoms/Tooltip'
import { updateSortConfig } from '../../../utils/datatable/dataTableUtil'
import {
  useLazyCorporationListQuery,
  useDeleteCorporationMutation
} from '../../../state/features/corporations/corporationsSlice'
import usePagination from '../../../hooks/usePagination'
import SearchField from '../../Atoms/SearchInput'
import { ReactComponent as Corporation } from '../../../images/icon/no-device.svg'
import Table from '../../Templates/Table'
import { IColumnProp, IColumnProps } from '../../../types/table'
import Pagination from '../../Modecules/Pagination/Pagination'
import Loader from '../../Atoms/Loader/Loader'
import { ICorporationResponse } from '../../../types/corporation'
import StatusButton from '../../Atoms/StatusButton'
import CommonDialog from '../../Atoms/Dialog'
import { ReactComponent as MenuIcon } from '../../../images/icon/menu.svg'
import { ReactComponent as Delete } from '../../../images/icon/delete.svg'
import { ReactComponent as DeleteIconModal } from '../../../images/icon/modal-delete.svg'
import StatusFilter from '../../Modecules/StatusFilter'
import { statusFilterType } from '../../../state/features/corporations/statusSlice'
import getUtcDate from '../../../utils/getUtcDate'

const CorporateList = (): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [selectedStatusList, setSelectedStatusList] = useState<
    statusFilterType[]
  >([])
  const [selectedStatusId, setSelectedStatusId] = useState<number[]>([])
  const [filterQuery, setFilterQuery] = useState({
    searchText: '',
    sortDirection: 'ASC',
    sortColName: 'corpId',
    rowLimitPerPage: TABLE_ROW_NUMBER_PER_PAGE[2],
    currentPageNumber: 0,
    // TODO: need to be changed according to Api payload
    statusIdList: selectedStatusId
  })
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none
  })

  const [
    trigger,
    {
      data: corporationList,
      isSuccess: corporationListIsSuccess,
      // refetch: corporationListRefetch,
      isFetching: corporationListIsReloading
    }
  ] = useLazyCorporationListQuery()
  const [
    deleteCorporation,
    {
      data: deleteCorporationData,
      isSuccess: deleteCorporationSuccess,
      isError: deleteCorporationError,
      isLoading: deleteCorporationIsLoading,
      error: deleteCorporationErrorData
    }
  ] = useDeleteCorporationMutation()

  const sortFieldsNameReplace = (substr: string) => {
    // corpId|company|imei1|imei2|iccid|productType|simExpiryDt|latestTransDt|deviceStatus
    if (substr === 'corp_id') {
      return 'corpId'
    }
    if (substr === 'company') {
      return 'company'
    }
    if (substr === 'status_code') {
      return 'statusCode'
    }
    if (substr === 'created_dt') {
      return 'createdDt'
    }
    if (substr === 'trial_end_dt') {
      return 'expiredDt'
    }

    return ''
  }

  const {
    currentPage,
    setCurrentPage,
    onClickPrevPage,
    onClickNextPage,
    rowsPerPage,
    onChangeRowNumber,
    onClickFirstPage,
    onClickLastPage,
    changeCurrentPage
  } = usePagination(
    TABLE_ROW_NUMBER_PER_PAGE[2],
    0,
    corporationList ? corporationList?.data?.total_corporation_count : 0
  )

  const onClickSortButton = (key: string) => {
    const sortConfigUpdate: SortConfig = updateSortConfig(key, sortConfig)
    setSortConfig(sortConfigUpdate)
    setFilterQuery({
      ...filterQuery,
      sortDirection:
        sortConfigUpdate.key === '' ? 'ASC' : sortConfigUpdate.direction,
      sortColName:
        sortConfigUpdate.direction === DIRECTION_TYPE.none
          ? 'corpId'
          : sortFieldsNameReplace(key)
    })
    changeCurrentPage(0)
  }

  // start delete corporation
  const [openPopperId, setOpenPopperId] = useState<string | null>(null)
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false)
  const buttonRefs = useRef<Array<React.RefObject<HTMLButtonElement>>>([])

  const handleDeleteMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    id: string
  ) => {
    event.stopPropagation()

    if (openPopperId === id && isPopperOpen) {
      setIsPopperOpen(!openPopperId)
    } else {
      setIsPopperOpen(true)
    }
    setOpenPopperId(id)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the event target is not inside any of the buttonRefs
      if (
        !buttonRefs.current.some((ref) =>
          ref.current?.contains(event.target as Node)
        )
      ) {
        // Clicked outside any of the buttons
        setOpenPopperId(null)
        setIsPopperOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [buttonRefs])

  // delete modal
  const [deleteCorporationModal, setDeleteCorporationModal] = useState<{
    state: boolean
    corpId: string
  }>({ state: false, corpId: '' })

  useEffect(() => {
    if (deleteCorporationSuccess) {
      toast.success('Corporation Successfully Deleted.')
    }
    if (deleteCorporationError) {
      if (deleteCorporationErrorData.data?.message) {
        toast.error(deleteCorporationErrorData.data.message)
      } else {
        toast.error('Failed to Delete Corporation.')
      }
    }
  }, [deleteCorporationSuccess, deleteCorporationError])

  const confirmDelete = () => {
    deleteCorporation({ corpId: deleteCorporationModal.corpId })
    setDeleteCorporationModal({ state: false, corpId: '' })
  }
  // end delete corporation

  const columnProps: IColumnProps = [
    {
      key: 'corp_id',
      name: 'Corporate ID',
      colWidth: 200,
      colMaxWidth: 200,
      isSort: true
    },
    {
      key: 'company',
      name: 'Corporate Name',
      colWidth: 220,
      colMaxWidth: 220,
      isSort: true
    },
    {
      key: 'status_code',
      name: 'Status',
      colWidth: 85,
      colMaxWidth: 85,
      isSort: true
    },
    {
      key: 'created_dt',
      name: 'Use Start Date',
      colWidth: 135,
      colMaxWidth: 135,
      isSort: true
    },
    {
      key: 'trial_end_dt',
      name: 'Trial End Date(UTC)',
      colWidth: 220,
      colMaxWidth: 220,
      isSort: true
    },
    {
      key: 'week_status_code',
      name: '1W Reminder',
      colWidth: 85,
      colMaxWidth: 85,
      isSort: false
    },
    {
      key: 'day_status_code',
      name: '1D Reminder',
      colWidth: 85,
      colMaxWidth: 85,
      isSort: false
    },
    {
      key: 'delete',
      name: '',
      colWidth: 58,
      colMaxWidth: 58,
      isSort: false
    }
  ]

  const cellDataGenerator = (
    cell: IColumnProp,
    row: ICorporationResponse,
    index: number
  ) => {
    // Create a ref for each button
    buttonRefs.current[index] =
      buttonRefs.current[index] || React.createRef<HTMLButtonElement>()

    switch (cell.key) {
      case 'corp_id':
        if (row[cell.key]) {
          return (
            <CustomTooltip
              toolTipTitle={row[cell.key]}
              maxWidth={cell.colMaxWidth}
            />
          )
        }

        return '-'
      case 'company':
        if (row[cell.key]) {
          return (
            <CustomTooltip
              toolTipTitle={row[cell.key]}
              maxWidth={cell.colMaxWidth}
            />
          )
        }

        return '-'

      case 'status_code':
        if (row[cell.key]) {
          const statusCode = row[cell.key]

          return (
            <StatusButton
              bgColor={STATUS_BG_COLOR[statusCode]}
              text={STATUS_TEXT[statusCode]}
            />
          )
        }

        return '-'
      case 'week_status_code':
        if (row[cell.key]) {
          const statusCode = row[cell.key]

          return (
            <StatusButton
              bgColor={STATUS_BG_COLOR[statusCode]}
              text={STATUS_TEXT[statusCode]}
            />
          )
        }

        return '-'
      case 'day_status_code':
        if (row[cell.key]) {
          const statusCode = row[cell.key]

          return (
            <StatusButton
              bgColor={STATUS_BG_COLOR[statusCode]}
              text={STATUS_TEXT[statusCode]}
            />
          )
        }

        return '-'

      case 'created_dt':
        if (row[cell.key]) {
          return format(new Date(row[cell.key]), 'MMM d, yyyy  HH:mm')
        }

        return '-'
      case 'trial_end_dt':
        if (row[cell.key]) {
          const time = getUtcDate(row[cell.key])

          return row.is_trial_date_modified ? `${time} (Edited)` : time
        }

        return '-'
      case 'delete':
        return (
          <Box className={classes.deleteIconBox}>
            <Button
              id='deleteButton'
              variant='contained'
              className={classes.deleteIconBtn}
              aria-controls={`simple-popper-${row['corp_id']}`}
              aria-describedby={`simple-popper-${row['corp_id']}`}
              aria-haspopup='true'
              onClick={(event) => {
                event.stopPropagation()
                handleDeleteMenuOpen(event, row['corp_id'])
              }}
              ref={buttonRefs.current[index]} // Use the ref corresponding to the current row
            >
              <MenuIcon />
            </Button>
            {openPopperId === row['corp_id'] && isPopperOpen && (
              <Box className={classes.deletePopper}>
                <ListItemButton
                  disableRipple
                  onClick={() => {
                    setDeleteCorporationModal({
                      state: true,
                      corpId: row['corp_id']
                    })
                  }}
                  className={classes.deletePopperBtn}
                >
                  <Delete />
                  <p style={{ padding: '0', margin: '0' }}>Delete</p>
                </ListItemButton>
              </Box>
            )}
          </Box>
        )

      default:
        return '-'
    }
  }

  const getPageDataIndexTo = () => {
    if (corporationList) {
      if (
        (currentPage + 1) * rowsPerPage >
        corporationList.data.total_corporation_count
      ) {
        return corporationList.data.total_corporation_count
      }

      return (currentPage + 1) * rowsPerPage
    }

    return 0
  }

  useEffect(() => {
    void trigger(filterQuery, false)
  }, [filterQuery, trigger])

  useEffect(() => {
    setCurrentPage(0)
  }, [filterQuery.searchText, setCurrentPage])

  useEffect(() => {
    setFilterQuery((prev) => ({
      ...prev,
      currentPageNumber: currentPage,
      rowLimitPerPage: rowsPerPage
    }))
  }, [currentPage, rowsPerPage])

  useEffect(() => {
    const selectedIds = selectedStatusList?.map(
      (region: statusFilterType) => region?.id
    )

    setSelectedStatusId(selectedIds)

    setFilterQuery((prev) => ({
      ...prev,
      // TODO: need to be changed according to Api payload
      statusIdList: selectedIds
    }))

    setCurrentPage(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatusList])

  return (
    <PageTemplate>
      <div className={classes.searchBlockWithFilter}>
        <SearchField
          clickFilterSearch={(val, isChange) => {
            if (isChange) {
              setFilterQuery((prevState) => ({
                ...prevState,
                searchText: val
              }))
            }
          }}
          placeholder={`${t('corporateList.searchPlaceholderText')}`}
        />
        <StatusFilter setSelectedStatusList={setSelectedStatusList} />
      </div>
      <Box className={classes.tableCard}>
        <div>
          <Typography className={classes.tableDataCount}>
            {`${t('corporateList.headerTitle')}`} (
            {corporationList?.data?.total_corporation_count ?? 0})
          </Typography>
        </div>
        {corporationList?.data.total_corporation_count === 0 ? (
          <div className={classes.dataNotFoundBlock}>
            <Corporation />
            <p className={classes.noFountText}>
              {t('corporateList.noCorporationFound')}
            </p>
            <p className={classes.noAvailableText}>
              {t('corporateList.currentlyNoCorporations')}
            </p>
          </div>
        ) : (
          <Box className={classes.dataTableWrap}>
            <SimpleBar>
              <Table
                hyperlink={
                  !isPopperOpen
                    ? {
                        valid: true,
                        path: PAGE_PATH_NAME.COMPANY_DETAILS,
                        state: 'corp_info_id'
                      }
                    : undefined
                }
                tableWidth='1382px'
                dataRows={
                  corporationList ? corporationList.data.corporation_list : []
                }
                clickSortButton={onClickSortButton}
                sortConfig={sortConfig}
                isSuccess={corporationListIsSuccess}
                isReloading={
                  corporationListIsReloading || deleteCorporationIsLoading
                }
                skeletonProps={columnProps}
                columnProps={columnProps}
                cellDataGenerator={cellDataGenerator}
              />
            </SimpleBar>
            {(corporationListIsReloading || deleteCorporationIsLoading) &&
            corporationListIsSuccess ? (
              <Box
                sx={{
                  width: '40px',
                  height: '40px',
                  position: 'absolute',
                  top: '45%',
                  left: '50%',
                  zIndex: '1'
                }}
              >
                <Loader colorCode='#939598' />
              </Box>
            ) : null}
            <div className={classes.dataPagination}>
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(
                  (corporationList
                    ? corporationList.data.total_corporation_count
                    : 0) / rowsPerPage
                )}
                clickPrevPageButton={onClickPrevPage}
                clickNextPageButton={onClickNextPage}
                rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
                currentRowNumberPerPage={rowsPerPage}
                changeRowNumber={onChangeRowNumber}
                containFirstAndLastButton
                clickFirstPageButton={onClickFirstPage}
                clickLastPageButton={onClickLastPage}
                pageDataIndexFrom={currentPage * rowsPerPage + 1}
                pageDataIndexTo={getPageDataIndexTo()}
                totalDataRows={
                  corporationList
                    ? corporationList.data?.total_corporation_count
                    : 0
                }
              />
            </div>
          </Box>
        )}
      </Box>
      <CommonDialog
        open={deleteCorporationModal.state}
        handleClose={() => {
          setDeleteCorporationModal({ state: false, corpId: '' })
        }}
        handleSubmit={() => {
          confirmDelete()
        }}
        icon={DeleteIconModal}
        title='Are you sure you want to delete this Corporation?'
        description='This action cannot be undone.'
        cancelText='Cancel'
        successText='Delete'
        isModalTop
      />
    </PageTemplate>
  )
}

export default CorporateList
