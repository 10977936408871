import LoadingSkeleton from '../../Atoms/TableSkeleton'
import LoadingSkeletonEula from '../../Atoms/TableSkeletonEula'
import { ITableProps } from '../../../types/table'
import THeader from '../../Modecules/Table/THeader'
import TBody from '../../Modecules/Table/TBody'
import { useStyles } from '../../../themes'

const Table: React.FC<ITableProps> = ({
  type = 'single-line',
  tableWidth,
  dataRows,
  clickSortButton,
  sortConfig,
  isSuccess,
  isReloading,
  columnProps,
  skeletonProps,
  cellDataGenerator,
  hyperlink,
  gap,
  pageSrc
}): JSX.Element => {
  const classes = useStyles()

  return (
    <div
      className={`${classes.dataTable} ${
        type === 'multi-line' ? classes.dataTableMulti : ''
      }`}
      style={{ minWidth: tableWidth }}
    >
      <THeader
        clickSortButton={clickSortButton}
        sortConfig={sortConfig}
        columnProperties={columnProps}
        gap={gap}
        pageSrc={pageSrc}
      />
      <div className={classes.dataTableBody}>
        {!isSuccess || (isReloading && pageSrc === 'eula') ? (
          (!isSuccess || isReloading) && pageSrc === 'eula' ? (
            <LoadingSkeletonEula gap={gap} skeletonProps={skeletonProps} />
          ) : (
            <LoadingSkeleton gap={gap} skeletonProps={skeletonProps} />
          )
        ) : (
          <TBody
            type={type}
            rows={dataRows}
            isSuccess={isSuccess}
            isReloading={isReloading as boolean}
            columnProperties={columnProps}
            cellDataGenerator={cellDataGenerator}
            hyperlink={hyperlink}
            gap={gap}
            pageSrc={pageSrc}
          />
        )}
      </div>
    </div>
  )
}

export default Table
