import React, { useState, useEffect } from 'react'
import { Box, Typography, Button } from '@mui/material'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { IColumnProp, IColumnProps } from '../../../types/table'
import { DIRECTION_TYPE, SortConfig } from '../../../types/datatable'
import { updateSortConfig } from '../../../utils/datatable/dataTableUtil'
import {
  useLazyEulaConsentListQuery,
  useUpdateEulaInformationMutation
} from '../../../state/features/eula/eulaSlice'
import PageTemplate from '../PageTemplate'
import CustomTooltip from '../../Atoms/Tooltip'
import { useStyles } from '../../../themes'
import SimpleBar from 'simplebar-react'
import Table from '../../Templates/Table'
import { IEulaResponse } from '../../../types/eula'
import { ReactComponent as UpdateIconBlue } from '../../../images/icon/update-linkblue.svg'
import EulaUpdateDialog from '../../Modecules/EulaUpdateDialog'

const EulaManagement = (): JSX.Element => {
  const classes = useStyles()
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none
  })
  const [eulaUpdateModal, setEulaUpdateModal] = useState<{
    state: boolean
    eulaType: string
  }>({ state: false, eulaType: '' })

  const [
    trigger,
    {
      data: dataList,
      isFetching: isEulaListFetching,
      isSuccess: isEulaListSuccess
    }
  ] = useLazyEulaConsentListQuery()

  useEffect(() => {
    void trigger({})
  }, [])

  const [
    updateEulaInformation,
    {
      data: eulaUpdatedData,
      isSuccess: isEulaUpdateSuccess,
      isLoading: isLoadingEulaUpdate,
      isError: isEulaUpdateError,
      error: eulaUpdateError
    }
  ] = useUpdateEulaInformationMutation()

  useEffect(() => {
    if (eulaUpdatedData) {
      if (eulaUpdateModal.eulaType === 'ToU') {
        toast.success('Terms of Use has been updated.')
      } else {
        toast.success('Privacy Policy has been updated.')
      }
      setEulaUpdateModal({ state: false, eulaType: '' })
    }
    if (isEulaUpdateError) {
      if (eulaUpdateError.data?.message) {
        toast.error(eulaUpdateError.data.message)
      } else {
        toast.error('Failed to Update Information.')
      }
      setEulaUpdateModal({ state: false, eulaType: '' })
    }
  }, [eulaUpdatedData, isEulaUpdateError])

  const columnProps: IColumnProps = [
    {
      key: 'title',
      name: 'Title',
      colWidth: 120,
      colMaxWidth: 120,
      isSort: false
    },
    {
      key: 'version',
      name: 'Version',
      colWidth: 60,
      colMaxWidth: 60,
      isSort: false
    },
    {
      key: 'lastUpdated',
      name: 'Last Updated',
      colWidth: 130,
      colMaxWidth: 130,
      isSort: false
    },
    {
      key: 'urlEn',
      name: 'English Ver. URL',
      colWidth: 270,
      colMaxWidth: 270,
      isSort: false
    },
    {
      key: 'urlJp',
      name: 'Japanese Ver. URL',
      colWidth: 270,
      colMaxWidth: 270,
      isSort: false
    },
    {
      key: 'update',
      name: '',
      colWidth: 81,
      colMaxWidth: 81
    }
  ]

  const cellDataGenerator = (
    cell: IColumnProp,
    row: IEulaResponse,
    index: number
  ) => {
    switch (cell.key) {
      case 'title': {
        if (row[cell.key]) {
          return row[cell.key]
        }

        return '-'
      }
      case 'version': {
        if (row[cell.key]) {
          return (
            <CustomTooltip
              toolTipTitle={row[cell.key]}
              maxWidth={cell.colMaxWidth}
            />
          )
        }

        return '-'
      }
      case 'lastUpdated': {
        if (row[cell.key]) {
          return format(new Date(row[cell.key]), 'MMM d, yyyy  HH:mm')
        }

        return '-'
      }
      case 'urlEn': {
        if (row[cell.key]) {
          return (
            <CustomTooltip
              toolTipTitle={row[cell.key]}
              maxWidth={cell.colMaxWidth}
            />
          )
        }

        return '-'
      }
      case 'urlJp': {
        if (row[cell.key]) {
          return (
            <CustomTooltip
              toolTipTitle={row[cell.key]}
              maxWidth={cell.colMaxWidth}
            />
          )
        }

        return '-'
      }
      case 'update': {
        return (
          <Button
            id='eulaUpdateButton'
            className={classes.eulaUpdateBtn}
            onClick={() => {
              setEulaUpdateModal({
                state: true,
                eulaType: row['eulaType']
              })
            }}
            disableRipple
          >
            <UpdateIconBlue />
            Update
          </Button>
        )
      }
      default:
        break
    }

    return ''
  }
  const onClickSortButton = (key: string) => {
    const sortConfigUpdate: SortConfig = updateSortConfig(key, sortConfig)
    setSortConfig(sortConfigUpdate)
  }

  return (
    <PageTemplate>
      <Box className={classes.eulaListCard}>
        <Box sx={{ marginTop: '-3px' }}>
          <Typography className={classes.eulaTableTitle}>
            End User License Agreements
          </Typography>
          <Box sx={{ marginTop: '-1px' }}>
            <SimpleBar>
              <Table
                tableWidth='1170px'
                dataRows={dataList ? dataList.data?.eulas : []}
                clickSortButton={onClickSortButton}
                sortConfig={sortConfig}
                isSuccess={isEulaListSuccess}
                isReloading={isEulaListFetching}
                skeletonProps={columnProps}
                columnProps={columnProps}
                cellDataGenerator={cellDataGenerator}
                pageSrc='eula'
              />
            </SimpleBar>
          </Box>
        </Box>
      </Box>
      {eulaUpdateModal.state && (
        <EulaUpdateDialog
          handleClose={() => {
            setEulaUpdateModal({ state: false, eulaType: '' })
          }}
          open={eulaUpdateModal.state}
          eulaType={eulaUpdateModal.eulaType}
          updateEulaInformation={updateEulaInformation}
          isLoadingEulaUpdate={isLoadingEulaUpdate}
        />
      )}
    </PageTemplate>
  )
}

export default EulaManagement
