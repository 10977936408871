import { MONTHS } from '../constants/constants'

const getUtcDate = (dateString: string): string => {
  const date = new Date(dateString)

  // Check if the date is valid
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, no-restricted-globals
  if (isNaN(date.getTime())) {
    return '-'
  }
  // Extract UTC components
  const year = date.getUTCFullYear()
  const day = String(date.getUTCDate()).padStart(2, '0')
  const hours = String(date.getUTCHours()).padStart(2, '0')
  const minutes = String(date.getUTCMinutes()).padStart(2, '0')

  // Format the date in UTC

  const formattedDateCustom = `${
    MONTHS[date.getUTCMonth()]
  } ${day}, ${year} ${hours}:${minutes}`

  return formattedDateCustom
}
export default getUtcDate
