import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import { makeStyles } from '@mui/styles'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Skeleton } from '@mui/material'
import { format } from 'date-fns'
import PageTemplate from '../PageTemplate'
import Datepicker from '../../Atoms/Datepicker'
import ToggleSwitch from '../../Atoms/ToggleSwitch'
import CustomTooltip from '../../Atoms/Tooltip'
import Button from '../../Atoms/Buttons'
import { useLazyCorporationDetailQuery } from '../../../state/features/corporations/corporationsSlice'
import { ICorporationDetailParams } from '../../../types/corporation'
import { ReactComponent as ArrowRight } from '../../../images/icon/arrowRightWhite.svg'
import { STATUS_BG_COLOR, STATUS_TEXT } from '../../../constants/constants'
import getUtcDate from '../../../utils/getUtcDate'

export const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    gap: '20px',
    marginTop: '12px',
    fontSize: '14px',
    lineHeight: '20px',
    alignItems: 'flex-start'
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'stretch',
    rowGap: '40px',
    backgroundColor: '#ffffff',
    padding: '24px 32px 30px 32px',
    borderRadius: '8px'
  },
  rightContainer: { rowGap: '22px' },
  info: {
    display: 'grid',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  rightCard: {
    display: 'flex',
    gap: '131px',
    '@media screen and (max-width: 1400px)': {
      gap: '50px'
    }
  },
  column3: {
    gridTemplateColumns: '32% 28% 30%'
  },
  element: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '6px'
  },
  chip: {
    height: '25px !important',
    padding: '3px 0px 5px !important'
  },

  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
  },
  proceedBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  proceedBtn: {
    height: '38px',
    fontSize: '14px',
    fontWeight: 'normal',
    display: 'flex',
    gap: '5px',
    padding: '10px 14px'
  },
  infoCard: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '9px',
    alignItems: 'flex-start',
    marginBottom: '11px'
  }
}))

type TextProps = {
  children: React.ReactNode
  /* eslint-disable*/
  color?: string
  fontWeight?: string
  fontSize?: string
  lineHeight?: string
}
const Text = ({
  children,
  color = '#232f3e',
  fontSize = '14px',
  fontWeight = '400',
  lineHeight = '20px'
}: TextProps) => (
  <Typography
    color={color}
    textAlign='left'
    fontSize={fontSize}
    fontWeight={fontWeight}
    lineHeight={lineHeight}
  >
    {children}
  </Typography>
)

const CorporateDetails = () => {
  const location = useLocation()
  const classes = useStyles()

  const [
    fetchCorporationDetailAPI,
    {
      data: corporationDetailsData,
      isLoading: corpDtlDataIsLoading,
      error: corporationDetailsFetchError,
      isError: corporationDetailsFetchIsError
    }
  ] = useLazyCorporationDetailQuery()

  const backToPreviousPage = (message: string) => {
    toast.error(message)
    setTimeout(() => {
      window.history.back()
    }, 400)
  }

  if (corporationDetailsFetchIsError) {
    backToPreviousPage(corporationDetailsFetchError.data.message)
  }
  const fetchDeviceDetails = () => {
    if (location.state) {
      void fetchCorporationDetailAPI({
        corp_info_id: (location.state as ICorporationDetailParams).corp_info_id
      })
    } else {
      backToPreviousPage('No Device Found')
    }
  }
  useEffect(() => {
    fetchDeviceDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCorporationDetailAPI, location.state])

  return (
    <PageTemplate>
      <Box className={classes.proceedBtnContainer}>
        <Button
          className={classes.proceedBtn}
          color='primary'
          onClick={() => {}}
        >
          <ArrowRight />
          Proceed To Contract
        </Button>
      </Box>
      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          <Box className={`${classes.info} ${classes.column3}`}>
            <Box className={classes.element}>
              <Text fontWeight='700'>Corporate ID</Text>
              {corpDtlDataIsLoading ? (
                <Skeleton
                  variant='text'
                  animation='wave'
                  sx={{ p: '0px', width: '90px', height: '20px' }}
                />
              ) : (
                <CustomTooltip
                  toolTipTitle={corporationDetailsData?.data?.corp_id}
                  maxWidth={167}
                  lineHeight={'20px'}
                  height={'20px'}
                  fontSize='14px'
                />
              )}
            </Box>
            <Box className={classes.element}>
              <Text fontWeight='700'>Corporate Name</Text>
              {corpDtlDataIsLoading ? (
                <Skeleton
                  variant='text'
                  animation='wave'
                  sx={{ p: '0px', width: '90px', height: '20px' }}
                />
              ) : (
                <CustomTooltip
                  toolTipTitle={corporationDetailsData?.data?.company}
                  maxWidth={100}
                  lineHeight={'20px'}
                  height={'20px'}
                  fontSize='14px'
                />
              )}
            </Box>
            <Box className={classes.element}>
              <Text fontWeight='700'>Main User Email Address</Text>
              {corpDtlDataIsLoading ? (
                <Skeleton
                  variant='text'
                  animation='wave'
                  sx={{ p: '0px', width: '90px', height: '20px' }}
                />
              ) : (
                <CustomTooltip
                  toolTipTitle={corporationDetailsData?.data?.mail_address}
                  maxWidth={175}
                  lineHeight={'20px'}
                  height={'20px'}
                  fontSize='14px'
                />
              )}
            </Box>
          </Box>
          <Box className={`${classes.info} ${classes.column3}`}>
            <Box className={classes.element}>
              <Text fontWeight='700'>User Count</Text>
              {corpDtlDataIsLoading ? (
                <Skeleton
                  variant='text'
                  animation='wave'
                  sx={{ p: '0px', width: '90px', height: '20px' }}
                />
              ) : (
                <CustomTooltip
                  toolTipTitle={corporationDetailsData?.data?.user_count}
                  maxWidth={180}
                  lineHeight={'20px'}
                  height={'20px'}
                  fontSize='14px'
                />
              )}
            </Box>
            <Box className={classes.element}>
              <Text fontWeight='700'>Device Count</Text>
              {corpDtlDataIsLoading ? (
                <Skeleton
                  variant='text'
                  animation='wave'
                  sx={{ p: '0px', width: '90px', height: '20px' }}
                />
              ) : (
                <CustomTooltip
                  toolTipTitle={corporationDetailsData?.data?.device_count}
                  maxWidth={180}
                  lineHeight={'20px'}
                  height={'20px'}
                  fontSize='14px'
                />
              )}
            </Box>
            <Box className={classes.element}>
              <Text fontWeight='700'>Use Start Date</Text>
              {corpDtlDataIsLoading ? (
                <Skeleton
                  variant='text'
                  animation='wave'
                  sx={{ p: '0px', width: '90px', height: '20px' }}
                />
              ) : (
                <Text>
                  {corporationDetailsData?.data.use_start_date
                    ? format(
                        new Date(corporationDetailsData.data.use_start_date),
                        'MMM d, yyyy  HH:mm'
                      )
                    : '-'}
                </Text>
              )}
            </Box>
          </Box>

          <Box className={classes.element}>
            <Text fontWeight='700'>Status</Text>
            {corpDtlDataIsLoading ? (
              <Skeleton
                variant='text'
                animation='wave'
                sx={{
                  p: '0px',
                  width: '57px',
                  height: '25px',
                  borderRadius: '16px'
                }}
              />
            ) : (
              corporationDetailsData?.data.status_code && (
                <Chip
                  label={
                    STATUS_TEXT[
                      corporationDetailsData?.data.status_code ?? 'NONE'
                    ]
                  }
                  className={classes.chip}
                  sx={{
                    color: 'white',
                    backgroundColor:
                      STATUS_BG_COLOR[
                        corporationDetailsData?.data.status_code ?? 'NONE'
                      ],
                    '.MuiChip-label': {
                      padding: '0px 10px 1px',
                      fontSize: '12px',
                      lineHeight: '24px'
                    }
                  }}
                />
              )
            )}
          </Box>
        </Box>
        <Box className={`${classes.container} ${classes.rightContainer}`}>
          <Box className={classes.rightCard}>
            <Box className={classes.element}>
              <Text fontWeight='700'>Trial Start Date (UTC)</Text>
              {corpDtlDataIsLoading ? (
                <Skeleton
                  variant='text'
                  animation='wave'
                  sx={{ p: '0px', width: '90px', height: '20px' }}
                />
              ) : (
                <Text>
                  {corporationDetailsData?.data.created_dt
                    ? getUtcDate(corporationDetailsData.data.created_dt)
                    : '-'}
                </Text>
              )}
            </Box>
            <Box className={classes.element} sx={{ width: '208px' }}>
              <Text fontWeight='700'>Trial End Date (UTC)</Text>
              {corpDtlDataIsLoading ? (
                <Skeleton
                  variant='text'
                  animation='wave'
                  sx={{ p: '0px', width: '208px', height: '38px' }}
                />
              ) : (
                corporationDetailsData?.data.trial_end_dt && (
                  <Datepicker
                    onDateChange={(date) => {}}
                    value={new Date(corporationDetailsData?.data.trial_end_dt)}
                    editedFlag={
                      corporationDetailsData?.data.is_trial_date_modified
                    }
                    utcDate={true}
                  />
                )
              )}
            </Box>
          </Box>
          <Box>
            <Text fontWeight='700'>Trial Reminder Status</Text>
            <Box sx={{ display: 'flex', gap: '30px', marginTop: '6px' }}>
              <Box className={classes.infoCard}>
                <Box className={classes.switchContainer}>
                  <Text>1W Reminder</Text>
                  {corpDtlDataIsLoading ? (
                    <Skeleton
                      variant='text'
                      animation='wave'
                      sx={{
                        p: '0px',
                        width: '49px',
                        height: '22px',
                        borderRadius: '16px'
                      }}
                    />
                  ) : (
                    corporationDetailsData?.data.week_reminder_flag && (
                      <ToggleSwitch
                        isBig={true}
                        switchStatus={
                          corporationDetailsData?.data.week_reminder_flag
                        }
                      />
                    )
                  )}
                </Box>
                {corpDtlDataIsLoading ? (
                  <Skeleton
                    variant='text'
                    animation='wave'
                    sx={{ p: '0px', width: '90px', height: '20px' }}
                  />
                ) : (
                  <Text>
                    {corporationDetailsData?.data.week_reminder_date
                      ? getUtcDate(
                          corporationDetailsData.data.week_reminder_date
                        )
                      : '-'}
                  </Text>
                )}
                {corpDtlDataIsLoading ? (
                  <Skeleton
                    variant='text'
                    animation='wave'
                    sx={{
                      p: '0px',
                      width: '57px',
                      height: '25px',
                      borderRadius: '16px'
                    }}
                  />
                ) : (
                  corporationDetailsData?.data.week_status_code && (
                    <Chip
                      label={
                        STATUS_TEXT[
                          corporationDetailsData?.data.week_status_code ??
                            'NONE'
                        ]
                      }
                      className={classes.chip}
                      sx={{
                        color: 'white',
                        backgroundColor:
                          STATUS_BG_COLOR[
                            corporationDetailsData?.data.week_status_code ??
                              'NONE'
                          ],
                        '.MuiChip-label': {
                          padding: '0px 10px 1px',
                          fontSize: '12px',
                          lineHeight: '24px'
                        }
                      }}
                    />
                  )
                )}
              </Box>
              <Box className={classes.infoCard}>
                <Box className={classes.switchContainer}>
                  <Text>1D Reminder</Text>
                  {corpDtlDataIsLoading ? (
                    <Skeleton
                      variant='text'
                      animation='wave'
                      sx={{
                        p: '0px',
                        width: '49px',
                        height: '22px',
                        borderRadius: '16px'
                      }}
                    />
                  ) : (
                    corporationDetailsData?.data.day_reminder_flag && (
                      <ToggleSwitch
                        isBig={true}
                        switchStatus={
                          corporationDetailsData?.data.day_reminder_flag
                        }
                      />
                    )
                  )}
                </Box>
                {corpDtlDataIsLoading ? (
                  <Skeleton
                    variant='text'
                    animation='wave'
                    sx={{
                      p: '0px',
                      width: '90px',
                      height: '20px'
                    }}
                  />
                ) : (
                  <Text>
                    {corporationDetailsData?.data.day_reminder_date
                      ? getUtcDate(
                          corporationDetailsData.data.day_reminder_date
                        )
                      : '-'}
                  </Text>
                )}
                {corpDtlDataIsLoading ? (
                  <Skeleton
                    variant='text'
                    animation='wave'
                    sx={{
                      p: '0px',
                      width: '57px',
                      height: '25px',
                      borderRadius: '16px'
                    }}
                  />
                ) : (
                  corporationDetailsData?.data.day_status_code && (
                    <Chip
                      label={
                        STATUS_TEXT[
                          corporationDetailsData?.data.day_status_code ?? 'NONE'
                        ]
                      }
                      className={classes.chip}
                      sx={{
                        color: 'white',
                        backgroundColor:
                          STATUS_BG_COLOR[
                            corporationDetailsData?.data.day_status_code ??
                              'NONE'
                          ],
                        '.MuiChip-label': {
                          padding: '0px 10px 1px',
                          fontSize: '12px',
                          lineHeight: '24px'
                        }
                      }}
                    />
                  )
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </PageTemplate>
  )
}

export default CorporateDetails
