import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import Switch, { SwitchProps } from '@mui/material/Switch';

interface CustomSwitchProps extends SwitchProps {
  isBig?: boolean;
}

const IOSSwitch = styled((props: CustomSwitchProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, isBig }) => ({
  width: isBig ? 49 : 39,
  height: isBig ? 22 : 18,
  padding: 0,
  overflow: 'unset',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transitionDuration: '300ms',
    '& .MuiSwitch-input': {
      width: '100%',
      left: '0%',
    },
    '&.Mui-checked': {
      transform: isBig ? 'translateX(28px)' : 'translateX(22px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#009DE1',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 1,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 1 : 1,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0px 1px 3px #0000001A',
    border: '1px solid #DEDFDF',
    boxSizing: 'border-box',
    width: isBig ? 22 : 18,
    height: isBig ? 22 : 18,
  },
  '& .MuiSwitch-track': {
    borderRadius: '20px',
    backgroundColor: '#D9D9D9',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

/**
 * Toggle switch props
 */
type Props = {
  // switch status
  switchStatus: boolean;
  isBig?: boolean;
};

// eslint-disable-next-line arrow-body-style
const ToggleSwitch = React.memo<Props>((props): JSX.Element => {
  return (
    <FormGroup>
      <IOSSwitch isBig={props.isBig} checked={props.switchStatus} />
    </FormGroup>
  );
});

export default ToggleSwitch;