/* eslint-disable @typescript-eslint/no-explicit-any */
import apiSlice from '../../api/apiSlice'
import { IEulaListResponse } from '../../../types/eula'

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ['EulaList']
})

export const eulaSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    eulaConsentList: builder.query<IEulaListResponse, any>({
      query: () => ({
        url: '/private/eula-list',
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),
      providesTags: ['EulaList']
    }),
    getEulaInformation: builder.query<any, any>({
      query: ({ eulaType }: any) => ({
        url: `/private/get-eula-detail?eulaType=${eulaType}`,
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
    }),
    updateEulaInformation: builder.mutation<any, any>({
      query: (body) => ({
        url: '/private/update-eula-detail',
        method: 'PUT',
        body,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['EulaList']
    })
  })
})

export const {
  useEulaConsentListQuery,
  useLazyEulaConsentListQuery,
  useGetEulaInformationQuery,
  useLazyGetEulaInformationQuery,
  useUpdateEulaInformationMutation
} = eulaSlice
